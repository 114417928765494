<template>
  <div>
    <div class="content-header">
      <div class="title-1">
        <router-link to="/"
          ><img src="../assets/back_arrow.svg" /><span style="color: #44D62C;"
            >Home</span
          ></router-link
        >
      </div>
    </div>

    <div class="single-games">
      <div class="avialable-c">
        <h3>Available Coin</h3>
        <h5>
          <img src="../assets/gold-coins-icon.svg" />
          <span>{{ totalCoin }}</span>
        </h5>
      </div>
    </div>
    <div class="packs-sec-1" v-if="autoRenewal.length">
      <div class="pack-title">Subscription Packages</div>

      <div class="pack-details-1">
        <div
          class="pack-2"
          v-bind:class="{ 'not-active': autoPackage.status === 'pending' }"
          v-for="autoPackage in autoRenewal"
          :key="autoPackage.package_id"
        >
          <h1 class="color-01">{{ autoPackage.title }}</h1>
          <h2><img :src="host + autoPackage.thumbnail_path" /></h2>
          <h3>{{ autoPackage.coin_amount }}</h3>
          <h4>
            {{ autoPackage.price }} Taka<br /><span style="font-size: 10px;"
              >(VAT+SD+SC)<br /><span style="color: red;"
                >* Auto Renewal</span
              ></span
            >
          </h4>
          <h5>
            <router-link
              :to="{
                name: 'BuyCoin',
                params: { packageId: autoPackage.package_id }
              }"
              >BUY</router-link
            >
          </h5>
          <div class="not-active-2" v-if="autoPackage.status === 'pending'">
            <h3>Coming Soon</h3>
          </div>
        </div>
      </div>
    </div>
    <div class="packs-sec-1" v-if="packages.length">
      <div class="pack-title">One Time Packages</div>

      <div class="pack-details-1">
        <div
          class="pack-2"
          v-bind:class="{ 'not-active': coin.status === 'pending' }"
          v-for="coin in packages"
          :key="coin.package_id"
        >
          <h1 class="color-01">{{ coin.title }}</h1>
          <h2><img :src="host + coin.thumbnail_path" /></h2>
          <h3>{{ coin.coin_amount }}</h3>
          <h4>
            {{ coin.price }} Taka<br /><span style="font-size: 10px;"
              >(VAT+SD+SC)</span
            >
          </h4>
          <h5>
            <router-link
              :to="{ name: 'BuyCoin', params: { packageId: coin.package_id } }"
              >BUY</router-link
            >
          </h5>
          <div class="not-active-2" v-if="coin.status === 'pending'">
            <h3>Coming Soon</h3>
          </div>
        </div>
      </div>
    </div>
    <footer>
      <div class="main-footer">
        <div class="social-icon"></div>
      </div>
    </footer>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "PurchaseCoin",
  data: () => {
    return {
      packages: [],
      autoRenewal: [],
      totalCoin: 0,
      phone: localStorage.getItem("phone"),
      host: null
    };
  },
  created() {
    this.host = this.$root.url();
    if (!localStorage.getItem("phone")) {
      this.$router.push({ name: "Registration" });
    }
    const path = "coinList";
    const url = `${this.host}/${path}?phone=${localStorage.getItem("phone")}`;
    axios
      .get(url)
      .then(response => {
        if (response.data.success === true) {
          this.packages = response.data.packages;
          this.autoRenewal = response.data.autoRenewal;
          this.totalCoin = response.data.totalCoin;
        } else {
          if (response.data.code === 3004) {
            localStorage.clear();
            this.$router.push({ name: "Registration" });
          }
          this.packages = [];
          this.totalCoin = 0;
        }
      })
      .catch(error => {
        console.log(error);
      });
  },
  methods: {
    buyCoin: function() {
      // console.log(localStorage.getItem("phone")); //do some task for coin purchase
    }
  }
};
</script>

<style scoped></style>
